<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
  >
    <el-form ref="form" :model="entity" label-width="100px" :rules="rules">
      <el-form-item label="所属公司">
        <CompanySearchSelect
          v-model="entity.CompanyId"
          @select="CompanySelect"
        />
      </el-form-item>
      <el-form-item label="客户" prop="cCusName">
        <CustomerSearchSelect
          v-model="entity.cCusName"
          @select="handleSelect"
          :disabled="!entity.CompanyId"
          :CompanyId="entity.CompanyId"
        />
      </el-form-item>
      <el-form-item label="签收人" prop="cName">
        <el-input v-model="entity.cName"></el-input>
      </el-form-item>
      <el-form-item label="签收电话" prop="cPhone">
        <el-input v-model="entity.cPhone"></el-input>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <span style="float: left; line-height: 3; color: red"
        >注:初始密码为手机号后六位</span
      >
      <el-button type="primary" @click="sendCode" v-if="title == '编辑签收人'"
        >发送</el-button
      >
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
import CustomerSearchSelect from "@/components/CustomerSearchSelect.vue";
export default {
  props: {
    parentObj: { type: Object },
  },
  components: {
    CompanySearchSelect,
    CustomerSearchSelect,
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      entity: {},
      rules: {
        cCusName: [
          { required: true, message: "请输入客户名称", trigger: "change" },
        ],
        cName: [
          { required: true, message: "请输入签收人名称", trigger: "blur" },
        ],
        cPhone: [{ required: true, validator: checkPhone, trigger: "blur" }],
      },
      btnLoading: false,
      title: "",
      loading: false,
      timeout: null,
    };
  },
  methods: {
    sendCode() {
      if (!this.entity.cPhone) return this.$message.error("请维护客户手机号");
      this.$http
        .post("/BO/CustomerLinkman/GetMessagePwd?Phone=" + this.entity.cPhone)
        .then((res) => {
          if (res.Success) {
            this.$message.success("密码已成功发送至客户手机");
          } else {
            this.$message.error("发送失败!请稍后重试");
          }
        });
    },
    // 公司选择
    CompanySelect(row) {
      console.log(row);
      this.$set(this.entity, "cCusCode", "");
      this.$set(this.entity, "cCusName", "");
    },
    init() {
      this.entity = {};
      this.drawer = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    openForm(Id, CompanyId) {
      this.init();
      if (CompanyId) {
        this.entity.CompanyId = CompanyId;
      }
      this.title = "新增签收人";
      if (Id) {
        this.title = "编辑签收人";
        this.getTheData(Id);
      }
    },
    handleClose(done) {
      done();
    },
    handleSelect(item) {
      this.entity.cCusCode = item.cCusCode;
      this.entity.cCusName = item.cCusName;
      this.entity.cCusId = item.Id;
    },
    getTheData(id) {
      this.$http.post("/BO/CustomerLinkman/GetTheData", { id }).then((res) => {
        if (res.Success) {
          this.entity = res.Data;
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.btnLoading = true;
        this.$http
          .post("/BO/CustomerLinkman/SaveData", this.entity)
          .then((res) => {
            this.btnLoading = false;
            if (res.Success) {
              this.$message.success("操作成功");
              this.parentObj.getDataList();
              this.drawer = false;
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
  },
};
</script>

<style>
.drawerClass {
  padding: 10px;
}
</style>
